<template>
  <c-card ref="widgetRef" class="@container">
    <c-card-title>Help</c-card-title>

    <scroll-shadow :style="limitHeightStyles">
      <c-card-content ref="contentRef">
        <p class="text-system text-sm">Learn more about CSC service usage</p>

        <c-list class="gap-2 text-base">
          <a
            v-for="(link, index) in links"
            :key="index"
            :href="link.to"
            target="_blank"
            rel="noopener noreferrer"
          >
            <c-list-item class="border" ripple>
              <c-list-item-title class="text-primary">
                {{ link.title }}
              </c-list-item-title>

              {{ link.subtitle }}

              <c-icon-button slot="post" text>
                <c-icon :path="mdiArrowRight" />
              </c-icon-button>
            </c-list-item>
          </a>
        </c-list>
      </c-card-content>
    </scroll-shadow>
  </c-card>
</template>

<script setup lang="ts">
import { mdiArrowRight } from '@mdi/js';

const props = withDefaults(defineProps<{ limitHeight?: boolean }>(), {
  limitHeight: false,
});

const limitHeightStyles = ref({ maxHeight: 'auto' });

const widgetRef = ref<HTMLCCardElement | null>(null);
const contentRef = ref<HTMLCCardContentElement | null>(null);

useResizeObserver(widgetRef, () => {
  if (!props.limitHeight) return;

  limitHeightStyles.value = { maxHeight: '9999px' };

  requestAnimationFrame(() => {
    const bottomMargin = 8;

    const { height } = document.documentElement.getBoundingClientRect();
    const { height: widgetHeight, top } =
      widgetRef.value!.getBoundingClientRect();
    const { height: contentHeight } = contentRef.value!.getBoundingClientRect();

    const isFitting = top + widgetHeight + bottomMargin <= height;

    if (!isFitting) {
      const widgetTopContentHeight = widgetHeight - contentHeight;

      const bottomOverflow =
        (height - contentHeight - top - widgetTopContentHeight) * -1;

      const newHeight = contentHeight - bottomOverflow - bottomMargin;

      limitHeightStyles.value = { maxHeight: `${newHeight}px` };
    }
  });
});

const links = [
  {
    title: 'Creating a project',
    subtitle: 'How to create a new project in My CSC',
    to: 'https://docs.csc.fi/accounts/how-to-create-new-project/',
  },
  {
    title: 'Adding service access',
    subtitle: 'How to activate a service for a project in My CSC',
    to: 'https://docs.csc.fi/accounts/how-to-add-service-access-for-project/',
  },
  {
    title: 'Billing Units',
    subtitle: 'What is a billing unit?',
    to: 'https://research.csc.fi/billing-units',
  },
  {
    title: 'Applying for billing units',
    subtitle: 'How to apply for more billing units',
    to: 'https://docs.csc.fi/accounts/how-to-apply-for-billing-units/',
  },
  {
    title: 'Documentation',
    subtitle: 'User guides and tutorials',
    to: 'https://docs.csc.fi/',
  },
  {
    title: 'CSC Computing Environment - Self-learning',
    subtitle: 'Online self-learning course about CSC Computing Environment',
    to: 'https://csc.fi/en/training-calendar/csc-computing-environment-self-learning/',
  },
  {
    title: 'CSC Computing Environment - Materials',
    subtitle: 'Materials for the CSC Computing Environment -course',
    to: 'https://csc-training.github.io/csc-env-eff/',
  },
];
</script>

<style lang="scss"></style>
